:root {
    --color-day-inactive: #D3D3D3;
    --color-day-active: #DD0000;
    --color-day-selected: #FFCE00;
    --day-min-height: 60px;
    --is-selected-issue: bold;
    --header-font-size:18px;
    --default-font-size:16px;
    --easy-select-font-size:20px;
    --date-colour: #06143d;
    --navbar-item: #06143d;
    --navbar-is-active: #DD0000;
  }
