@import "./design-layout/var-coincidence.scss";
/* For mobile phones: */
html,body{font-family:Verdana,sans-serif;font-size:var(--default-font-size);line-height:1.5}
html{overflow-x:hidden;
}
body{margin:4px}

p{
  font-size: var(--default-font-size);

}

#title-page {
  padding-top: 100px;
  text-align: center;
}

#title-page .main {
  font-size: 48px;
}

#title-page .by {
  font-size: 36px;
}
footer {
  margin-top: 40px;
}

.default-text {
  font-size: var(--default-font-size);
}

.easy-text {
  font-size: var(--easy-select-font-size);
}

.header-text {
  font-size: var(--header-font-size);
}

textarea,input{
  font-size: var(--default-font-size);
}

#update-day {
  font-size: var(--easy-select-font-size);
}



#cal-control {
  text-align: center;
  font-size: var(--easy-select-font-size);
}

#main-area {
  margin-top: 32px;
}


#main-menu {
  position: fixed;
  top:0px;
  padding-top: 2px;
  background-color: #fff;
  height:30px;
  margin: 0;
  width:100%;
}

#menu-container {
  width: 100%; 
  margin-left: 2%;
  margin-right: 2%;
}

.navbar-item {
  float: left;
  text-align: center;
  width: 16%;
  color: var(--navbar-item );
  text-decoration: none;
}
.navbar-is-active {
  border-bottom: 2px;
  border-bottom-style: solid;
  border-bottom-color: var(--navbar-is-active);
}

.blank_day {
  display: none; 
} 

.grid-container {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 50% 50%;
}

#analysis-week-view >.day {
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: black;
  min-height: var(--day-min-height);
}


#weekview >.day {
  padding-top: 8px;
  padding-bottom: 8px;
  border-style: solid;
  min-height: var(--day-min-height);
}

#weekview >.day.inactive {
border-color:var(--color-day-inactive);
}

.day.inactive > .date-format {
  color:#808080;
}
.update-day {
  height: 100%;
  border-style: solid;
  border-color:var(--color-day-active);
  border-radius: 25px;
  padding-left: 10px;
  padding-right: 10px;
  }

#weekview >.day.active {
border-color:var(--color-day-active);
border-radius: 25px;
padding:0px 10px 0px 10px;
}


#weekview >.day.selected  {
padding-top: 4px;
padding-bottom: 4px;
border-color: var(--color-day-selected);
border-width: 5px;  
}
.date-format{
  font-size: var(--default-font-size);
  text-align: center;
  color: var(--date-colour);
  font-weight: bold;
}

.factor-issue-list{
  list-style-type: none;
  padding-left: 0px;
  padding-bottom: 20px;
}

.listissues{
  padding-left: 20px;
}

.listfactors{
  padding-left: 20px;
}

.unselected {
  opacity: 0.2;
}

#analysis-big-grid {
  display: grid;
  grid-template-columns: 40% 60%;

}

#analysis-figures-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  background-color: #2196F3;
  padding: 10px;
}
.analysis-grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px 10px 20px 10px;
  font-size: var(--header-font-size);
  text-align: center;
  vertical-align: center;
  list-style-type: none;
}

.analysis-desc {
  font-size: var(--default-font-size);
}
.sel-analysis {
  margin: 4px;
  border: 2px solid black;
}

.analysis-factor-list {
  list-style-type: none;
  padding:0px;
  margin: 0px;
}

video {
  width: 100%;
  height: auto;
}

video-horiz {
  width: auto;
  height: 100%;
}
.is-selected-issue {
  font-weight: var(--is-selected-issue);
}

.float-left {
  float:left
}
.float-right {
  float:right;
}
.pos5 {
  background-color: #FF0a00;
  margin-bottom: 1px;
  }
  
  .pos4 {
    background-color: #FF6e00;
    margin-bottom: 1px;
  }
  
  .pos3 {
  background-color: #FFd200;
  margin-bottom: 1px;  
}
  
  .pos2 {
  background-color: #3eff00;
  margin-bottom: 1px;  
}
  
  .pos1 {
  background-color: #00d4ff;
  margin-bottom: 1px;  
}
  
  .pos0 {
  background-color: #ffffff;
  }
  

@media only screen and (min-width: 768px) {
    body {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
          "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
          sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      video {
        width: auto;
        height: auto;
      }
      
      code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
          monospace;
      }
      
      #big-grid {
          display: grid;
          grid-template-columns: 10% 90%;
          grid-template-areas:
       'h h'
       'cc a'
       'f c'
       't t';
      }
      
      #header {
        text-align: center;
          grid-area: h;
      }
      
      #analysis {
        text-align: center;
        grid-area: a;
      }
      #cal-control {
        grid-area: cc;
      }
      
      #footer {
          grid-area: t;
      }

      #analysis-week-view { 
        overflow: auto; /* new */
        width:100%;
      }

      #weekview { 
        overflow: auto; /* new */
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          grid-area: c;
      }

      #weekview >.blank_day {
        display: block;
      }
      
      #issues{
          grid-area: f;
      }
      
      #factors{
          grid-area: f;
      }
      
      .factor-issue-list{
          list-style-type: none;
          padding-left: 0;
      }
      .listissues{
        min-height: 20px;
      }


      .button {
        border: solid;
        color: black;
        padding: 0.4em;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 1em;
        margin: 4px 2px;
        cursor: pointer;
        -webkit-transition-duration: 0.4s; /* Safari */
        transition-duration: 0.4s;
      }
      
      .button:hover {
        background-color: blue; /* Green */
        color: white;
      }
      
      .button1 {border-radius: 2px;}
      .button2 {border-radius: 4px;}
      .button3 {border-radius: 8px;}
      .button4 {border-radius: 12px;}
      .button5 {border-radius: 50%;}
      
      .removebutton{
        background-color: white;
         float: right;
      }
      
}


 /* Tooltip container */
 .tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
} 
